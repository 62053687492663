'use strict';

angular.module('enervexSalesappApp').controller('UpdatesCtrl', function(Auth, $scope, $stateParams, User, GlobalActivity, State, Todolist, Job, Todo, Discussion) {
	$scope.accountId = $stateParams.accountId;
	$scope.isAdmin = Auth.isAdmin;
	$scope.accessAllAccounts = Auth.accessAllAccounts;
	if($scope.accountId != 'new'){
		GlobalActivity.query({}).$promise.then(function(activities){
			$scope.activities = activities;
		});

		$scope.updatesLimit = 5;
		$scope.removeUpdatesLimit = function(){
			$scope.showAllUpdates = true;
			$scope.updatesLimit = $scope.activities.length;
		}
		$scope.placeUpdatesLimit = function(){
			$scope.showAllUpdates = false;
			$scope.updatesLimit = 5;
		}
	}
});
